
import { ref, watch } from 'vue';
import moment from 'moment';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import ApiService from '@/core/services/ApiService';

export default {
  name: 'PartnerLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = [
      'Partner Account',
      ['Partner Account Lists'],
      { name: 'Verify Partner', path: 'verify' },
    ];

    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'User',
        key: 'profileImage',
        sortable: true,
      },
      {
        key: 'name',
        sortable: false,
      },
      {
        name: 'Role',
        key: 'role',
        sortable: true,
      },
      {
        name: 'Date Created',
        key: 'createdAt',
        sortable: true,
      },
      {
        name: 'Status',
        key: 'verifyState',
        sortable: true,
      },
      {
        name: 'Account Details',
        key: 'details',
        sortable: false,
      },
    ];

    const loopers = ['role', 'name', 'role'];
    const tableData = ref([]);
    const loading = ref(true);
    const tableRef = ref<any>(null);

    const changeStates = (table) => {
      table.forEach((item: any) => {
        const roles: any = [];
        const states: any = [];

        item.staff.verifyState === 'Done' && roles.push('Staff');
        item.driver.verifyState === 'Done' && roles.push('Driver');
        item.organization.verifyState === 'Done' &&
          roles.push(item.organization.type);

        item.role = roles.join(', ');

        states.push(item.staff.verifyState);
        states.push(item.driver.verifyState);
        states.push(item.organization.verifyState);

        if (
          states.some((state) => state === 'Done') &&
          !states.some((state) => state === 'WaitReviewVerify')
        ) {
          item.verifyState = 'Verified';
        } else if (states.some((state) => state === 'WaitReviewVerify')) {
          item.verifyState = 'Await Verification';
        } else {
          item.verifyState = 'Unverified';
        }
      });
    };

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        loading.value = true;

        const { data } = await ApiService.get(
          `partner?registerState=Done&page=${page}&pageSize=${rows}`
        );
        tableData.value = data.datas;
        tableRef.value.total = data.total;

        changeStates(tableData.value);

        loading.value = false;
      }
    );

    watch(
      () => tableData.value,
      async () => {
        changeStates(tableData.value);
      }
    );

    return {
      tableHeader,
      breadcrumbs,
      loopers,
      tableData,
      loading,
      moment,
      tableRef,
    };
  },
};
